<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Lịch sử hoạt động'">
          <template v-slot:preview>
            <b-form ref="form" lazy-validation class="row" v-if="!onLoading">
              <hr />

              <b-container class="bv-example-row">
                <b-row class="justify-content-md-center">
                  <b-col>
                    <b-row>
                      <b-col class="col-md-3">
                        <p class="header-color">Chương trình:</p>
                        <p class="header-color mt-2 mb-0">Hành động:</p>
                      </b-col>
                      <b-col>
                        <p>
                          {{ rewardAdhoc.name }}
                          <span
                            @click="goToRewardAdHocDetail(rewardAdhoc.id)"
                            type="button"
                            class="pl-2 text-primary hover-effect"
                            ><i
                              class="flaticon-eye text-primary hover-effect"
                            ></i>
                            Xem chi tiết</span
                          >
                        </p>

                        <p
                          v-text="getActionName(action)"
                          class="label font-weight-bold label-lg label-inline"
                          v-bind:class="{
                            'label-light-success': action === 'insert',
                            'label-light-warning': action === 'update',
                            'label-light-danger': action === 'delete',
                          }"
                          style="width: max-content"
                        ></p>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col>
                    <b-row>
                      <b-col class="col-md-3">
                        <p class="header-color">Người thao tác:</p>
                        <p class="header-color">Thời gian:</p>
                      </b-col>
                      <b-col>
                        <p>{{ creator }}</p>
                        <p>
                          {{ formatDate(createdAt, 'DD/MM/YYYY HH:mm:ss') }}
                        </p>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-container>

              <div class="w-100 mt-5 px-5 mb-5">
                <h5 class="bold-text">Chi tiết thay đổi thông tin</h5>
              </div>
              <b-container class="bv-example-row">
                <table
                  border="1"
                  class="table table-bordered table-vertical-center table-hover mb-10"
                >
                  <colgroup>
                    <col style="width: 20%" />
                    <col style="width: 40%" />
                    <col style="width: 40%" />
                  </colgroup>
                  <thead class="bg-secondary">
                    <tr>
                      <th rowspan="2">Dữ liệu</th>
                    </tr>
                    <tr>
                      <th>Trước khi sửa</th>
                      <th>Sau khi sửa</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in updatedFields" :key="index">
                      <th class="header-color">
                        {{ getFieldLabel(item.field) }}
                      </th>
                      <td
                        v-if="
                          item.field === 'startDate' || item.field === 'endDate'
                        "
                      >
                        {{ formatDate(item.oldValue, 'DD/MM/YYYY') }}
                      </td>
                      <td v-else>{{ item.oldValue }}</td>
                      <td
                        v-if="
                          item.field === 'startDate' || item.field === 'endDate'
                        "
                      >
                        {{ formatDate(item.newValue, 'DD/MM/YYYY') }}
                      </td>
                      <td v-else>{{ item.newValue }}</td>
                    </tr>
                  </tbody>
                </table>
              </b-container>
              <div class="w-100 mt-5 px-5 mb-5">
                <h5 class="bold-text">Chi tiết thay đổi sản phẩm</h5>
              </div>
              <b-container class="bv-example-row">
                <table
                  class="table table-bordered table-vertical-center table-hover mb-10"
                >
                  <tr>
                    <th style="width: 10%">Hành động</th>
                    <th style="width: 45%">Trước</th>
                    <th>Sau</th>
                  </tr>
                  <tr v-for="(item, index) in updatedDetails" :key="index">
                    <td>
                      <p
                        v-text="getActionName(item.action)"
                        class="label font-weight-bold label-lg label-inline"
                        v-bind:class="{
                          'label-light-success': item.action === 'insert',
                          'label-light-warning': item.action === 'update',
                          'label-light-danger': item.action === 'delete',
                        }"
                        style="width: max-content"
                      ></p>
                    </td>
                    <td>
                      <div
                        v-if="item.action === 'insert'"
                        v-show="item.action === 'insert'"
                      >
                        <span></span>
                      </div>

                      <div>
                        <span
                          v-if="item.action === 'update'"
                          v-show="item.action === 'update'"
                        >
                          <b-row>
                            <b-col class="col-sm-4 label-column">
                              <p>Loại:</p>
                              <p>ID:</p>
                              <p>Tên:</p>
                              <p v-if="isDefined(item.oldValues.areaBonus)">
                                Thưởng QL Khu vực:
                              </p>
                              <p v-if="isDefined(item.oldValues.managerBonus)">
                                Thưởng QL Cửa hàng:
                              </p>
                              <p v-if="isDefined(item.oldValues.assistantBonus)">
                                Thưởng TL Cửa hàng:
                              </p>
                              <p v-if="isDefined(item.oldValues.saleBonus)">
                                Thưởng NV Bán hàng:
                              </p>
                              <p v-if="isDefined(item.oldValues.technicalBonus)">
                                Thưởng NV Kỹ thuật:
                              </p>
                              <p v-if="isDefined(item.oldValues.cashierBonus)">
                                Thưởng thu ngân:
                              </p>
                              <p v-if="isDefined(item.oldValues.customerReceptionBonus)">
                                Thưởng tiếp đón KH:
                              </p>
                            </b-col>
                            <b-col class="value-column">
                              <p>
                                {{ getTypeDetailLabel(item.oldValues.type) }}
                              </p>
                              <p>{{ item.oldValues.referenceId }}</p>
                              <p class="name-ellipsis">
                                {{ item.oldValues.name || '.' }}
                              </p>
                              <p>{{ item.oldValues.areaBonus }}</p>
                              <p>{{ item.oldValues.managerBonus }}</p>
                              <p>{{ item.oldValues.assistantBonus }}</p>
                              <p>{{ item.oldValues.saleBonus }}</p>
                              <p>{{ item.oldValues.technicalBonus }}</p>
                              <p>{{ item.oldValues.cashierBonus }}</p>
                              <p>{{ item.oldValues.customerReceptionBonus }}</p>
                            </b-col>
                          </b-row>
                        </span>
                      </div>
                    </td>
                    <td>
                      <div
                        v-if="item.action === 'insert'"
                        v-show="item.action === 'insert'"
                      >
                        <b-row>
                          <b-col class="col-sm-4 label-column">
                            <p>Loại:</p>
                            <p>ID:</p>
                            <p>Tên:</p>
                            <p v-if="isDefined(item.newValues.areaBonus)">
                              Thưởng QL Khu vực:
                            </p>
                            <p v-if="isDefined(item.newValues.managerBonus)">
                              Thưởng QL Cửa hàng:
                            </p>
                            <p v-if="isDefined(item.newValues.assistantBonus)">
                              Thưởng TL Cửa hàng:
                            </p>
                            <p v-if="isDefined(item.newValues.saleBonus)">
                              Thưởng NV Bán hàng:
                            </p>
                            <p v-if="isDefined(item.newValues.technicalBonus)">
                              Thưởng NV Kỹ thuật:
                            </p>
                            <p v-if="isDefined(item.newValues.cashierBonus)">
                              Thưởng thu ngân:
                            </p>
                            <p
                              v-if="
                                isDefined(item.newValues.customerReceptionBonus)
                              "
                            >
                              Thưởng tiếp đón KH:
                            </p>
                          </b-col>
                          <b-col class="value-column">
                            <p>{{ getTypeDetailLabel(item.newValues.type) }}</p>
                            <p>{{ item.newValues.referenceId }}</p>
                            <p class="name-ellipsis">
                              {{ item.newValues.name || '.' }}
                            </p>
                            <p>{{ item.newValues.areaBonus }}</p>
                            <p>{{ item.newValues.managerBonus }}</p>
                            <p>{{ item.newValues.assistantBonus }}</p>
                            <p>{{ item.newValues.saleBonus }}</p>
                            <p>{{ item.newValues.technicalBonus }}</p>
                            <p>{{ item.newValues.cashierBonus }}</p>
                            <p>{{ item.newValues.customerReceptionBonus }}</p>
                          </b-col>
                        </b-row>
                      </div>

                      <div>
                        <span
                          v-if="item.action === 'update'"
                          v-show="item.action === 'update'"
                        >
                          <b-row>
                            <b-col class="col-sm-4 label-column">
                              <p>Loại:</p>
                              <p>ID:</p>
                              <p>Tên:</p>
                              <p v-if="isDefined(item.newValues.areaBonus)">
                                Thưởng QL Khu vực:
                              </p>
                              <p v-if="isDefined(item.newValues.managerBonus)">
                                Thưởng QL Cửa hàng:
                              </p>
                              <p
                                v-if="isDefined(item.newValues.assistantBonus)"
                              >
                                Thưởng TL Cửa hàng:
                              </p>
                              <p v-if="isDefined(item.newValues.saleBonus)">
                                Thưởng NV Bán hàng:
                              </p>
                              <p
                                v-if="isDefined(item.newValues.technicalBonus)"
                              >
                                Thưởng NV Kỹ thuật:
                              </p>
                              <p v-if="isDefined(item.newValues.cashierBonus)">
                                Thưởng thu ngân:
                              </p>
                              <p
                                v-if="
                                  isDefined(
                                    item.newValues.customerReceptionBonus,
                                  )
                                "
                              >
                                Thưởng tiếp đón KH:
                              </p>
                            </b-col>
                            <b-col class="value-column">
                              <p>
                                {{ getTypeDetailLabel(item.newValues.type) }}
                              </p>
                              <p>{{ item.newValues.referenceId }}</p>
                              <p class="name-ellipsis">
                                {{ item.newValues.name || '.' }}
                              </p>
                              <p class="text-danger">
                                {{ item.newValues.areaBonus }}
                              </p>
                              <p class="text-danger">
                                {{ item.newValues.managerBonus }}
                              </p>
                              <p class="text-danger">
                                {{ item.newValues.assistantBonus }}
                              </p>
                              <p class="text-danger">
                                {{ item.newValues.saleBonus }}
                              </p>
                              <p class="text-danger">
                                {{ item.newValues.technicalBonus }}
                              </p>
                              <p class="text-danger">
                                {{ item.newValues.cashierBonus }}
                              </p>
                              <p class="text-danger">
                                {{ item.newValues.customerReceptionBonus }}
                              </p>
                            </b-col>
                          </b-row>
                        </span>
                      </div>
                    </td>
                  </tr>
                </table>
              </b-container>
            </b-form>
          </template>
          <template v-slot:foot>
            <router-link to="/bill-history" tag="button">
              <b-button
                style="font-weight: 600; width: 70px"
                variant="secondary"
                size="sm"
                >Hủy</b-button
              >
            </router-link>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import { formatDate } from '@/utils/common';

export default {
  data() {
    return {
      id: null,
      rewardAdhocId: null,
      action: null,
      createdAt: null,
      creator: null,
      rewardAdhoc: null,
      updatedFields: [],
      updatedDetails: [],
      onLoading: true,
      listAction: [
        {
          id: 'insert',
          name: 'Thêm mới',
        },
        {
          id: 'update',
          name: 'Cập nhật',
        },
        {
          id: 'delete',
          name: 'Xóa',
        },
      ],
      listTypeDetails: [
        {
          id: 1,
          name: 'Sản phẩm',
        },
        {
          id: 2,
          name: 'Nhóm sản phẩm',
        },
      ],
    };
  },
  components: {
    KTCodePreview,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Lịch sử hóa đơn', route: '/bill-history' },
      { title: 'Chi tiết lịch sử hóa đơn', route: '/detail-bill-history' },
    ]);

    this.id = this.$route.query.id;
    this.getDetailHistory();
  },
  created() {},
  methods: {
    formatDate,
    getActionName: function (action) {
      return this.listAction.find((item) => item.id === action)?.name;
    },
    isDefined: function (value) {
      return value !== undefined;
    },
    convertPrice: function (number) {
      let result = new Intl.NumberFormat('vn-VN').format(number);
      return isNaN(parseFloat(result)) ? '' : result;
    },
    setCurrentAddedObj(obj) {
      let result = JSON.parse(obj);
      return result;
    },
    isModified(value) {
      return value === 'MODIFIED';
    },
    getHistoryType(typeId) {
      switch (typeId) {
        case 1:
          return 'Đơn hàng';
        case 2:
          return 'Hóa đơn';
        default:
          return '';
      }
    },
    goToRewardAdHocDetail(id) {
      const { href } = this.$router.resolve({
        name: 'upsert-reward-adhoc',
        query: { id },
      });

      return window.open(href, '_blank');
    },
    getFieldLabel(field) {
      const labels = {
        name: 'Tên chương trình',
        description: 'Mô tả',
        startDate: 'Từ ngày',
        endDate: 'Đến ngày',
      };
      return labels[field] || null;
    },
    getTypeDetailLabel(type) {
      return this.listTypeDetails.find((item) => item.id == type).name || '';
    },
    getDetailHistory: async function () {
      this.onLoading = true;
      await ApiService.setHeader();
      await ApiService.query('/reward-adhoc/histories/' + this.id).then(
        (response) => {
          const data = response.data.data;

          this.rewardAdhoc = data.rewardAdhoc;
          this.rewardAdhocId = data.rewardAdhocId;
          this.action = data.action;
          this.createdAt = data.createdAt;
          this.creator = data.creator;
          this.updatedFields = data.updatedFields;
          this.updatedDetails = data.updatedDetails;
        },
      );
      this.onLoading = false;
    },
    formatMoney(number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
  },
};
</script>

<style>
.header-color {
  font-weight: 500;
}

.bold-text {
  color: #212121;
}

.label-column {
  font-weight: bold;
  padding-right: 10px;
}

.value-column {
  min-width: 0;
  word-break: break-word;
  white-space: normal;
}

.name-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  display: block;
}
</style>
